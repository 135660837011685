@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/variables" as *;

.about {
  margin-top: 3rem;
  // height: 90rem;
  z-index: 1;
  @include tablet {
    height: 70rem;
  }
}

.about-flex {
  @include tablet {
    display: flex;
  }
}

.contact-header {
  margin: 1rem;
  text-align: center;
  padding-bottom: 3rem;
  @include tablet {
    padding-top: 5rem;
  }
}

.contact-nameheader {
  padding: 0rem;
  margin: 0.5rem;
  font-size: 3rem;
  font-weight: 600;
  text-align: center;

  @include tablet {
    display: none;
  }
}

.contact-subheader {
  padding: 0rem;
  margin: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  padding-bottom: 2rem;
  @include tablet {
    display: none;
  }
}

.contact-nameheadertablet {
  display: none;

  @include tablet {
    display: flex;
    padding-top: 7rem;
    margin: 0.5rem;
    font-size: 2.5rem;
    font-weight: 500;
    text-align: center;
    padding-bottom: 2rem;
  }

  @include desktop {
    display: none;
  }
}

.contact-subheadertablet {
  display: none;

  @include tablet {
    display: flex;
    margin: 0.5rem;
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
    padding-top: 0rem;
  }

  @include desktop {
    display: none;
  }
}

.contact-nameheaderdesktop {
  display: none;

  @include desktop {
    display: flex;
    padding: 0rem;
    margin: 0.5rem;
    font-size: 3rem;
    font-weight: 600;
    text-align: center;
  }
}

.contact-subheaderdesktop {
  display: none;

  @include desktop {
    display: flex;
    padding: 0rem;
    margin: 0.5rem;
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
    padding-bottom: 2rem;
  }
}

.contact-copy__header {
  //   margin: 2rem;
  text-align: center;
  //   padding-bottom: 1rem;

  //   @include desktop {
  //     padding-top: 1rem;
  //     width: 50rem;
  //     padding-right: 14rem;
  //   }
}

.contact-copy {
  margin: 1.5rem;
  text-align: center;
  padding-bottom: 1rem;
  line-height: 1.5;
  font-size: 1rem;
  font-weight: 300;

  @include tablet {
    margin: 1.5rem;
    text-align: center;
    padding-bottom: 1rem;
    font-size: 1rem;
  }
}

.contact-networks {
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact-picture {
  padding-top: 2rem;
  min-width: 50%;
  min-width: 50%;
  width: 70%;
  background-color: rgba(217, 55, 43, 0.095);
  border-radius: 9px;

  background-position: 4%;
  background-size: cover;

  @include tablet {
    width: 100%;
    max-width: 20rem;
  }
  @include desktop {
    max-width: 70%;
  }
}

.contact-picture__background {
  width: 30rem;
}

.contact-picture__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 2s, transform 2s;
}

.contact-picture__container.animate {
  opacity: 1;
  transform: translateY(0);
}

.network-picture {
  width: 3rem;
  margin-left: 1rem;
}

.contact-flex {
  width: 100%;
  justify-content: flex-end;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 2s, transform 2s;

  @include tablet {
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    align-items: center;
    width: 50%;
  }
}

.contact-flex.animate {
  opacity: 1;
  transform: translateY(0);
}
