@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/variables" as *;

.projects {
  margin-top: 9rem;
}

.projects-header {
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
}

.projects-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 2s, transform 2s;

  @include tablet {
  }
}

.projects-container.animate {
  opacity: 1;
  transform: translateY(0);
}

.projects-description {
  text-align: center;
  line-height: 1.8rem;
  font-weight: 300;
  margin: 1.5rem;
  max-width: 40rem;
}

.projects-video__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include desktop {
    display: flex;
    flex-direction: row;
  }
}

/* CSS */
.projects-link {
  align-items: center;
  background-image: linear-gradient(144deg, #af40ff, #5b42f3 50%, #00ddeb);
  border: 0;
  border-radius: 8px;
  box-shadow: rgba(255, 255, 255, 0.2) 0 15px 30px -5px;
  box-sizing: border-box;
  color: #ffffff;
  display: flex;
  font-size: 15px;
  font-weight: 300;
  justify-content: center;
  line-height: 1em;
  max-width: 30%;
  min-width: 140px;
  padding: 3px;
  margin: 1rem;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
  text-align: center;
}

.projects-link:active,
.projects-link:hover {
  outline: 0;
}

.projects-link span {
  background-color: rgb(5, 6, 45);
  padding: 12px 15px;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  transition: 300ms;
}

.projects-link:hover span {
  background: none;
}

@media (min-width: 768px) {
  .button-64 {
    font-size: 24px;
    min-width: 196px;
  }
}

.projects-buttons {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 5rem;
  padding-top: 2rem;
}

.projects-techstack {
  text-align: center;
}

.projects-todo__image {
  width: 35rem;
  padding-left: 3rem;

  @include tablet {
    width: 65rem;
    padding-left: 6rem;
  }

  @include desktop {
    width: 65rem;
  }
}

.projects-ball__image {
  width: 18rem;
  padding-left: 2rem;

  @include tablet {
    width: 25rem;
    padding-left: 2rem;
  }

  @include desktop {
    width: 25rem;
  }
}

.projects-amex__image {
  width: 30rem;
  padding-left: 1rem;

  @include tablet {
    width: 62rem;
  }

  @include desktop {
    width: 65rem;
  }
}

.projects-curby__image {
  width: 25rem;
  padding-left: 0rem;

  @include tablet {
    width: 55rem;
  }

  @include desktop {
    width: 65rem;
  }
}
