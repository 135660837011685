@font-face {
  font-family: Karla;
  src: url(../../assets/Font/Karla/static/Karla-ExtraLight.ttf);
  font-style: normal;
  font-display: swap;
  font-weight: 100;
}

@font-face {
  font-family: Karla;
  src: url(../../assets/Font/Karla/static/Karla-Light.ttf);
  font-style: normal;
  font-display: swap;
  font-weight: 200;
}

@font-face {
  font-family: Karla;
  src: url(../../assets/Font/Karla/static/Karla-Regular.ttf);
  font-style: normal;
  font-display: swap;
  font-weight: 300;
}

@font-face {
  font-family: Karla;
  src: url(../../assets/Font/Karla/static/Karla-Medium.ttf);
  font-style: normal;
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: Karla;
  src: url(../../assets/Font/Karla/static/Karla-SemiBold.ttf);
  font-style: normal;
  font-display: swap;
  font-weight: 600;
}

@font-face {
  font-family: Karla;
  src: url(../../assets/Font/Karla/static/Karla-Bold.ttf);
  font-style: normal;
  font-display: swap;
  font-weight: 700;
}

@font-face {
  font-family: Karla;
  src: url(../../assets/Font/Karla/static/Karla-ExtraBold.ttf);
  font-style: normal;
  font-display: swap;
  font-weight: 800;
}
