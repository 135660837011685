@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/variables" as *;

.profile-header {
  width: 20rem;
  @include tablet {
    width: 47rem;
  }

  @include desktop {
    width: 80rem;
  }
}

.home {
  position: relative;
  height: 65.7rem;
  background-color: rgb(248, 101, 91);
  z-index: 1; /* Updated z-index value */
  @include tablet {
    height: 65.7rem;
  }
}

.profile_container {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  text-align: center;
  color: white;
  font-size: 36px;
  font-weight: bold;
  @include tablet {
    top: 60%;
  }
}

.profile-subheader {
  font-size: 1rem;
  color: rgb(255, 255, 255);
  font-weight: 200;
  position: relative;
  bottom: 4rem;

  @include tablet {
    bottom: 11rem;
    font-size: 2rem;
  }
}

.bgImage {
  position: absolute;
  background-size: cover;
  background-position: top;
  z-index: -1;
}

.blobCont {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  // height: 100.5vh;
  height: 1051px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blob {
  position: absolute;
  top: 0;
  left: 0;
  will-change: transform;
  /* Rest of your styles */
}

.blob.animate {
  transition: transform 60s linear;
}

.blob {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.blob.active {
  transform: translate3d(100px, 0.1px, 0);
}

/* Animation for Chrome and other browsers */

@for $i from 1 through 18 {
  $a: #{$i * 60};
  $b: #{$i * 60 + 360};

  .blob:nth-child(#{$i}) {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    animation: move#{$i} 60s infinite linear;
  }

  @keyframes move#{$i} {
    from {
      transform: rotate(#{$a}deg) translate3d(100px, 0.1px, 0) rotate(-#{$a}deg);
    }
    to {
      transform: rotate(#{$b}deg) translate3d(100px, 0.1px, 0) rotate(-#{$b}deg);
    }
  }
}

/* CSS */
.profile-button {
  align-items: center;
  background-image: linear-gradient(144deg, #af40ff, #5b42f3 50%, #00ddeb);
  border: 0;
  border-radius: 8px;
  box-shadow: rgba(255, 255, 255, 0.2) 0 15px 30px -5px;
  box-sizing: border-box;
  color: #ffffff;
  display: flex;
  font-size: 15px;
  font-weight: 300;
  justify-content: center;
  line-height: 1em;
  max-width: 20%;
  min-width: 140px;
  padding: 3px;
  margin: 0.2rem;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
}

.profile-button:active,
.profile-button:hover {
  outline: 0;
}

.profile-button span {
  background-color: rgb(5, 6, 45);
  padding: 12px 15px;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  transition: 300ms;
}

.profile-button:hover span {
  background: none;
}

@media (min-width: 768px) {
  .button-64 {
    font-size: 24px;
    min-width: 196px;
  }
}

.profile-button__container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  @include tablet {
    position: relative;
    bottom: 5rem;
  }
  @include desktop {
    position: relative;
    bottom: 10rem;
  }
}

.profile_container.animate,
.bgImage.animate {
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
}

.profile_container.animate.active,
.bgImage.animate.active {
  opacity: 1;
}
