@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/variables" as *;

.skills-header {
  margin-top: 8rem;
  padding-bottom: 3rem;
  display: flex;
  justify-content: center;
  @include tablet {
    margin-bottom: 5rem;
  }
  @include desktop {
    padding-top: 5rem;
    margin-bottom: 9rem;
  }
}
.skills-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.skills-box {
  min-width: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
  border-radius: 5%;
  margin: 1rem;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 3s, transform 3s;

  @include tablet {
    min-width: 30%;
  }

  @include desktop {
    min-width: 20%;
  }
}

.skills-box.animate {
  opacity: 1;
  transform: translateY(0);
}

.skills-text {
  font-size: 1rem;
  padding: 0rem;
}
.skills-icon {
  width: 4rem;
  filter: saturate(190%);
}
