@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/variables" as *;

.footer {
  background-color: rgb(248, 101, 91);
  display: flex;
  justify-content: space-between;
}

.footer-text {
  color: #ffffff;
  padding: 0.2rem;
  margin: 1rem;
  text-align: center;
}
