@use "./styles/partials/mixins" as *;
@use "./styles/partials/typography" as *;
@use "./styles/partials/variables" as *;
// boxsizing

*,
*::before,
*::after {
  box-sizing: border-box;
}

.App {
  font-family: "Karla", Times, serif;
}

html,
body {
  overflow-x: hidden;
}
