@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/variables" as *;

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: #fff;
}

.navbar-list {
  display: flex;
  justify-content: center;
  list-style: none;
  font-weight: 400;
  padding: 0;
}

.navbar-list__item {
  cursor: pointer;
  padding: 0.5rem;
  color: #3b3b3c;
}
.navbar-list__item:hover {
  color: rgb(248, 101, 91);
}
